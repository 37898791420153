<template>
  <div
    class="w-full bg-#23261a/50 fixed h-full top-0 left-0 z-100"
    @click.self="emits('close')"
  >
    <div itemscope itemtype="http://schema.org/Organization" class="flex flex-col gap-5 p-4.5 pb-6 rounded-r-4.5 w-84 ring-0.75 ring-inset ring-#ededed bg-white shadow-burger-menu h-full overflow-auto">
      <Logo itemprop="logo" class="w-38 h-auto text-firmennyy shrink-0" />

      <div
        v-if="!selectedNavItem"
        class="flex flex-col"
      >
        <template
          v-for="(item, i) in items"
          :key="i"
        >
          <div
            v-if="item.items"
            class="flex items-center justify-between gap-3 text-tekstovyy text-4 font-500 leading-0.8 -tracking-0.01em py-4 first:pt-0 border-b border-plashka-seryy"
            @click="selectNavItems(item)"
          >
            {{ item.link.title }}
            <span class="text-3.25 text-firmennyy i-custom:dropdown-select -rotate-90"></span>
          </div>
          <NuxtLink
            v-else
            class="flex items-center justify-between gap-3 text-tekstovyy text-4 font-500 leading-0.8 -tracking-0.01em py-4 first:pt-0 border-b border-plashka-seryy"
            :to="item.link.to"
            @click="$emit('close')"
          >
            {{ item.link.title }}
          </NuxtLink>
        </template>
      </div>
      <div
        v-else
        class="flex flex-col gap-3.5"
      >
        <p class="flex items-center gap-2 text-tekstovyy text-5 font-500 leading-0.8 -tracking-0.01em">
          <span
            class="i-custom:full-arrow-left text-7.5 text-firmennyy cursor-pointer"
            @click="selectNavItems(null)"
          ></span>
          {{ selectedNavItem.link.title }}
        </p>
        <NuxtLink
          v-for="item in selectedNavItem.items"
          class="flex gap-2.5 items-center text-#52626b text-3.75 leading-0.8 font-500 -tracking-0.01em"
          :to="`${selectedNavItem.link.to}/${item.slug}`"
          @click="$emit('close')"
        >
          <div itemscope itemtype="http://schema.org/ImageObject" class="overflow-hidden size-10 rounded-1.5 ring-0.5 p-0.5 ring-border/50 ring-inset bg-white">
            <NuxtImg
              itemprop="contentUrl"
              width="40"
              height="40"
              class="w-full h-full object-center object-contain"
              :src="item.img_path"
              alt="icon"
              loading="lazy"
              placeholder
              fit="contain"
            />
            <meta itemprop="width" :content="String(40)" />
            <meta itemprop="height" :content="String(40)" />
          </div>
          {{ item.title }}
        </NuxtLink>
        <Button
          :tag="NuxtLink"
          :to="selectedNavItem.link.to"
          :ui="{ text: 'text-3.5', padding: 'py-3 px-7' }"
          color="green-light"
          @click="$emit('close')"
        >
          Все категории {{ selectedNavItem.items?.length }}
        </Button>
      </div>

      <ul class="flex flex-col gap-5">
        <li>
          <NuxtLink
            class="text-#455862 text-4 leading-0.8 -tracking-0.01em cursor-pointer"
            @click="openPartnerPopup(), $emit('close')"
          >
            Партнерам
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            class="text-#455862 text-4 leading-0.8 -tracking-0.01em"
            to="/contacts"
            @click="$emit('close')"
          >
            Контакты
          </NuxtLink>
        </li>
      </ul>
      <div class="flex flex-col gap-4 mt-auto">
        <div class="flex gap-3 text-white">
          <NuxtLink
            to="https://t.me/sakhfood"
            target="_blank"
            class="flex items-center justify-center size-8 rounded-full bg-firmennyy hover:bg-firmennyy-bright transition-colors"
          >
            <span class="i-custom:tg text-4.5 mr-0.5"></span>
          </NuxtLink>
        </div>

        <div class="h-px w-full bg-plashka-seryy"></div>

        <NuxtLink
          :to="`tel:${store.siteSettings?.phone}`"
          class="group/footerphone flex flex-col"
        >
          <span class="text-tekstovyy font-700 text-5 group-hover/footerphone:text-firmennyy-bright transition-colors">{{ formatPhoneNumber(store.siteSettings?.phone) }}</span>
          <span class="text-firmennyy font-500 text-3.75 group-hover/footerphone:text-firmennyy-bright transition-colors">Россия, бесплатно</span>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components';
import type { NavItem, Nav } from './type';
import { usePartnersPopup } from '~/composables/popups/usePartnersPopup';
import { siteSettingsStore } from '~/stores/site-settings-store';
import { formatPhoneNumber } from '~/assets/funcs/funcs';

const store = siteSettingsStore();
defineProps<{
	items: Nav
}>();
const emits = defineEmits<{
	close: []
}>();

const { openPartnerPopup } = usePartnersPopup();

const selectedNavItem = ref<NavItem | null>();

const selectNavItems = (item: NavItem | null) => {
  selectedNavItem.value = item;
};
</script>
